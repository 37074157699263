.pageWrapper {
  min-height: 100vh;
  background-color: #FFFCFB;
}
.contentArea {
  background-color: #FFFCFB;
  background-image: url('./background.png');
  flex-grow: 1;
  max-width: 1300px;
  margin: 0 auto;
}
.card {
  margin-bottom: 30px;
  background-color: #FFFCFB;
}

.circle-box {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 100%;
  &.lg-box {
    width: 60px;
    height: 60px;
    font-size: 21px;
    line-height: 60px;
  }
}
.button-group .btn {
  margin: 3px;
}
table th {
  font-weight: 500;
}

