.sidebarArea {
  flex-shrink: 0;
  width: $sidebarWidth;
  background-color: $sidebarColor;
  .nav-link {
    color: black;
  }
  .sidenav-bg:hover {
    background-color: #F8ECDC;
    border-radius: $border-radius;
    .nav-link {
      color: black;
    }
  }
  .sidenav-bg .active {
    background-color: #FFF2D6;
    border-radius: $border-radius;
    color: black;
  }
}

@include media-breakpoint-down(lg) {
  .sidebarArea {
    position: fixed;
    height: 100%;
    overflow: auto;
    top: 0;
    z-index: 1;
    margin-left: -$sidebarWidth;
    transition: 0.2s ease-in;
    &.showSidebar {
      margin-left: 0px;
    }
  }
}
